import { getField, updateField } from 'vuex-map-fields';
import sitesApi from '@/utils/api/sites';
import Site from '@/models/customers/site';
import { ActionStatus } from '@/constants/status';

export default {
    namespaced: true,
    state: () => ({
        siteFormVisibility: false,
        editedSiteId: null,
        siteData: new Site(),
        showTab: null,
        isFormFilled: false,
        nestedSites: [],
        showSiteDetails: false,
        activeSite: null,
        newAttachment: null,
        removeAttachment: false,
        attachmentStatus: ActionStatus.IDLE
    }),
    getters: {
        getField,
        getCoordinates: state => ({
            latitude: state.siteData.latitude,
            longitude: state.siteData.longitude
        })
    },
    mutations: {
        updateField,
        SET_SITE_DATA (state, payload) {
            state.siteData.parseData(payload);
        },
        SET_ATTACHMENT_STATUS (state, payload) {
            state.attachmentStatus = payload;
        },
        SET_EDITED_SITE_ID (state, payload) {
            state.editedSiteId = payload;
        },
        RESET_SITE_DATA (state) {
            state.siteData = new Site();
            state.newAttachment = null;
            state.removeAttachment = false;
            state.attachmentStatus = ActionStatus.IDLE;
        },
        SET_IS_FORM_FILLED (state, payload) {
            state.isFormFilled = payload;
        },
        RESET_NESTED_SITES (state) {
            state.nestedSites = [];
        },
        REMOVE_NESTED_SITE (state, nestedLocation) {
            state.nestedSites.splice(state.nestedSites.indexOf(nestedLocation), 1);
        },
        SET_NESTED_SITES (state, nestedLocations) {
            state.siteData.nestedLocations = nestedLocations;
            state.nestedSites.push(state.siteData);
        },
        SET_ACTIVE_SITE (state, payload) {
            let result;
            if (payload === null) {
                result = payload;
            } else {
                let employeesWithAccessList = '';
                let employeesWithAccessIds = [];

                if (payload.employees_with_access && payload.employees_with_access.length > 0) {
                    employeesWithAccessList = payload.employees_with_access.map(el => el.name).join(', ');
                    employeesWithAccessIds = payload.employees_with_access.map(el => el.id.toString());
                }

                // TODO: add new format placeGroups
                // let placeGroupsInclude = payload.included.filter(el => el.type === 'place_groups') || [];
                let placeGroups = [];
                let placeGroupsList = '';
                if (payload.place_groups && payload.place_groups.length > 0) {
                    placeGroupsList = payload.place_groups.map(el => el.name).join(', ');
                    placeGroups = payload.place_groups.map(el => el.id);
                }

                let phoneData = '';
                if (payload.phone_for_notifications && payload.phone_for_notifications.length > 0) {
                    phoneData = payload.phone_for_notifications
                        .map(el => `${el.full_name} (${el.phone_number})`).join(', ');
                }

                let emailData = '';
                if (payload.notifications_email && payload.notifications_email.length > 0) {
                    emailData = payload.notifications_email
                        .map(el => `${el.full_name} (${el.email})`).join(', ');
                }

                // TODO: nieobsłużone: payload.special_attention, payload.manager, payload.default_inspector, payload.deputy_id,
                // payload.is_deleted,
                // client: Object { id: Getter & Setter, name: Getter & Setter, … }

                result = {
                    id: payload.id,
                    name: payload.name,
                    city_id: payload.city.id.toString(),
                    street_and_number: payload.address,
                    address: `${payload.city.postal_code} ${payload.city.name} ${payload.address}`,

                    internal_identifier: payload.kronos_id,
                    external_identifier: payload.kronos_guid,
                    place_code: payload.code,
                    comment: payload.comment,
                    latitude: payload.latitude,
                    longitude: payload.longitude,
                    zip_code: payload.city.postal_code,
                    file: payload.file,
                    email_notification_employees_ids: payload.notifications_email.map(el => el.id),
                    phone_notification_employees_ids: payload.phone_for_notifications.map(el => el.id),
                    inform_all_inspections: payload.inform_all_inspections,

                    nestedLocations: [], // TODO: dodać

                    employees_with_access_list: employeesWithAccessList,
                    employees_with_access_ids: employeesWithAccessIds,
                    oldEmployeesWithAccessIds: employeesWithAccessIds,
                    place_groups: placeGroups,
                    place_groups_list: placeGroupsList,
                    phoneData: phoneData,
                    emailData: emailData
                };
            }
            state.activeSite = result;
        },
        CHANGE_ATTACHMENT (state, payload) {
            state.newAttachment = payload;
        },
        REMOVE_ATTACHMENT (state, payload) {
            state.removeAttachment = true;
        }
    },
    actions: {
        getActiveSite ({ rootState, dispatch }, id) {
            return sitesApi.getActiveSite(id, rootState.generalConfigurations.host)
                .then(({ data }) => {
                    dispatch('commitSiteData', data);
                });
        },
        commitSiteData ({ commit }, data) {
            commit('SET_ACTIVE_SITE', data);
            const locations = data.included?.filter(el => el.type === 'points');

            if (locations) {
                commit('customers/SET_LOCATIONS_PER_SITE', locations, { root: true });
            }
        },
        updateSite ({ state, rootState, commit, dispatch }) {
            const customerId = rootState.manageCustomer.activeCustomer.id || rootState.manageCustomer.editedCustomerId;
            const siteId = state.editedSiteId;
            let payload = state.siteData.getDataForUpdate(siteId, customerId);

            if (rootState.manageLocation.isLocationFormFilled) {
                payload = state.siteData.getDataForUpdateWithNestedLocations(siteId, customerId, rootState.manageLocation.nestedLocations);
            }

            const params = {
                params: {
                    'extra_fields[places]': 'employees_with_access,phone_data,email_data',
                    'include': 'points,city,place_groups'
                }
            };

            return sitesApi.updateSite(state.editedSiteId, payload, params)
                .then(({ data }) => {
                    dispatch('commitSiteData', data);
                    commit('RESET_SITE_DATA');
                    if (rootState.manageLocation.isLocationFormFilled) {
                        commit('manageLocation/SET_IS_LOCATION_FORM_FILLED', false, { root: true });
                        commit('manageLocation/RESET_NESTED_LOCATIONS', null, { root: true });
                    }
                });
        },
        update ({ state, rootState, commit, dispatch }) {
            const customerId = rootState.manageCustomer.activeCustomer?.id || rootState.manageCustomer.editedCustomerId;
            const siteId = state.editedSiteId;
            const payload = state.siteData.update(siteId, customerId);
            const newAttachment = state.newAttachment;
            const removeAttachment = state.removeAttachment;
            return sitesApi.update(state.editedSiteId, payload)
                .then(({ data }) => {
                    if (newAttachment) {
                        commit('SET_ATTACHMENT_STATUS', ActionStatus.PENDING);
                        dispatch('importSiteAttachment', {
                            id: data.data.id,
                            file: newAttachment
                        }).then(() => {
                            dispatch('getActiveSite', data.data.id).then(() => {
                                commit('SET_ATTACHMENT_STATUS', ActionStatus.FULFILLED);
                                commit('SET_SITE_DATA', state.activeSite);
                            });
                        }).catch(() => {
                            commit('SET_ATTACHMENT_STATUS', ActionStatus.REJECTED);
                        });
                    } else if (removeAttachment) {
                        dispatch('removeSiteAttachment', data.data.id).then(() => {
                            dispatch('getActiveSite', data.data.id).then(() => {
                                commit('SET_ATTACHMENT_STATUS', ActionStatus.IDLE);
                                commit('SET_SITE_DATA', state.activeSite);
                            });
                        });
                    } else {
                        dispatch('getActiveSite', data.data.id).then(() => {
                            commit('SET_SITE_DATA', state.activeSite);
                        });
                    }
                });
        },
        createSite ({ state, rootState, commit }) {
            const customerId = rootState.manageCustomer.activeCustomer.id;
            let payload = state.siteData.getDataForCreate(customerId);

            if (rootState.manageLocation.isLocationFormFilled) {
                payload = state.siteData.getDataForCreateWithNestedLocations(customerId, rootState.manageLocation.nestedLocations);
            }

            return sitesApi.createSite(payload)
                .then(() => {
                    commit('RESET_SITE_DATA');
                    if (rootState.manageLocation.isLocationFormFilled) {
                        commit('manageLocation/SET_IS_LOCATION_FORM_FILLED', false, { root: true });
                        commit('manageLocation/RESET_NESTED_LOCATIONS', null, { root: true });
                    }
                });
        },
        create ({ state, rootState, dispatch, commit }) {
            const customerId = rootState.manageCustomer.activeCustomer.id;
            const payload = state.siteData.getDataForCreate(customerId);
            const newAttachment = state.newAttachment;
            return sitesApi.createSite(payload)
                .then(({ data }) => {
                    if (newAttachment && data.data.id) {
                        commit('SET_ATTACHMENT_STATUS', ActionStatus.PENDING);
                        dispatch('importSiteAttachment', {
                            id: data.data.id,
                            file: newAttachment
                        }).then(() => {
                            dispatch('getActiveSite', data.data.id).then(() => {
                                commit('SET_ATTACHMENT_STATUS', ActionStatus.FULFILLED);
                                commit('SET_SITE_DATA', state.activeSite);
                                commit('SET_EDITED_SITE_ID', data.data.id);
                            });
                        }).catch(() => {
                            commit('SET_ATTACHMENT_STATUS', ActionStatus.REJECTED);
                        });
                    } else {
                        dispatch('getActiveSite', data.data.id).then(() => {
                            commit('SET_ATTACHMENT_STATUS', ActionStatus.IDLE);
                            commit('SET_SITE_DATA', state.activeSite);
                            commit('SET_EDITED_SITE_ID', data.data.id);
                        });
                    }
                });
        },
        deleteSite (context, id) {
            return sitesApi.deleteSite(id);
        },
        setNestedSites ({ commit, rootState }) {
            const nestedLocations = rootState.manageLocation.nestedLocations;

            commit('SET_NESTED_SITES', nestedLocations);
        },
        cleanActiveSiteInformation ({ commit }) {
            commit('SET_ACTIVE_SITE', null);
            commit('customers/SET_LOCATIONS_PER_SITE', [], { root: true });
        },
        importSiteAttachment (context, data) {
            return sitesApi.importSiteAttachment(data.id, {
                data: {
                    file: data.file
                }
            }, context.rootState.generalConfigurations.host);
        },
        removeSiteAttachment (context, id) {
            return sitesApi.removeSiteAttachment(id, context.rootState.generalConfigurations.host);
        }
    }
};
