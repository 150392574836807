import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getIncidents: (params) => {
        return HttpService.get(getEndpoint('incidents'), params);
    },
    getStatuses: (params) => {
        return HttpService.get(getEndpoint('incident_statuses'), {
            params: params
        });
    },
    getTypes: (params) => {
        return HttpService.get(getEndpoint('incident_kinds'), {
            params: params
        });
    },
    getSingleType: (id) => {
        return HttpService.get(getEndpoint('manage_incident_kinds', { id: id }));
    },
    getActiveIncident: (id) => {
        return HttpService.get(getEndpoint('manage_incident', { id: id }), {
            params: {
                'extra_fields[incidents]': 'questions_with_answer,files_content',
                include: 'incident_history_items,inspection,incident_kind,incident_status'
            }
        });
    },
    getActiveIncidentType: (id) => {
        return HttpService.get(getEndpoint('manage_incident_kinds', { id: id }), {
            params: {
                include: 'template,company_type,incident_statuses,employee_phones,employee_mails,incident_kind_employees_mails'
            }
        });
    },
    getSingleIncidentType: (id) => {
        return HttpService.get(getEndpoint('manage_incident_kinds', { id: id }), {
            params: {
                'extra_fields[incident_kinds]': 'assigned'
            }
        });
    },
    getActiveIncidentStatus: (id) => {
        return HttpService.get(getEndpoint('manage_incident_status', { id: id }));
    },
    createIncidentType: (data) => {
        return HttpService.post(getEndpoint('incident_kinds'), data);
    },
    createIncidentStatus: (data) => {
        return HttpService.post(getEndpoint('incident_statuses'), data);
    },
    updateIncidentType: (id, data) => {
        return HttpService.patch(getEndpoint('manage_incident_kinds', { id: id }), data);
    },
    updateIncidentStatus: (id, data) => {
        return HttpService.patch(getEndpoint('manage_incident_status', { id: id }), data);
    },
    deleteIncidentType: (id) => {
        return HttpService.delete(getEndpoint('manage_incident_kinds', { id: id }));
    },
    deleteIncidentStatus: (id) => {
        return HttpService.delete(getEndpoint('manage_incident_status', { id: id }));
    },
    updateManyStatuses: (payload) => {
        return HttpService.patch(getEndpoint('update_many_statuses'), payload);
    },
    deleteManyIncidents: (payload) => {
        return HttpService.delete(getEndpoint('destroy_many_event', { name: 'incidents' }), { data: payload });
    },
    assignKindsFromPoints: (data) => {
        return HttpService.patch(getEndpoint('assign_kinds_from_points'), data);
    },
    unassignKindsFromPoints: (data) => {
        return HttpService.patch(getEndpoint('unassign_kinds_from_points'), data);
    }
};
